// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Theme$GlazedWebsite from "../../Theme.js";
import * as Gatsby$GlazedWebsite from "../../shared/Gatsby.js";
import * as Heading$GlazedWebsite from "../../Heading.js";
import * as CaseStudyPageTemplate$GlazedWebsite from "./building-blocks/CaseStudyPageTemplate.js";
import * as LogoGlazedSmallSvg from "static/images/logo-glazed-small.svg";

var inPageHeading = Curry._1(Css.style, /* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.maxWidth(Css.rem(64)),
        /* :: */[
          Css.padding2(/* zero */-789508312, Css.rem(1.875)),
          /* :: */[
            Css.margin(/* auto */-1065951377),
            /* :: */[
              Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  inPageHeading: inPageHeading
};

var pageImagesQuery = (Gatsby.graphql`
     query {
       headerImage: file(relativePath: { eq: "case-studies/boston-hero.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       productImage: file(relativePath: { eq: "case-studies/boston-01.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       nextCaseImage: file(relativePath: { eq: "case-studies/lvmh-hero.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200, maxHeight: 820) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
     }
  `);

function Boston(Props) {
  var queryResult = Gatsby.useStaticQuery(pageImagesQuery);
  var headerImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "headerImage");
  var productImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "productImage");
  var nextCaseImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "nextCaseImage");
  var content_hero = {
    image: headerImage,
    title: "Boston Hospital",
    area: "HealthTech - IoT",
    text: "A bespoke iOS application to display the position of the physician inside the hospital."
  };
  var content_brief = {
    techs: [
      {
        name: "TensorFlow",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "iOS",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "BLE",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "iBeacons",
        icon: LogoGlazedSmallSvg.default
      }
    ],
    year: "2019",
    brief: "We\'ve developed a technological solution for physicians to provide live updates to their patients concerning their next appointment, including dynamically rescheduling appointments - all using positional tracking, beacons and machine learning capabilities."
  };
  var content_content = [
    /* TextAndVideo */Block.__(4, [
        "\n        It\'s easy to triangulate locations while outdoors, but as soon as we get walls into the mix, the situation changes.\n\n        This project\'s main challenge resided in being able to provide the accurate positioning of physicians within the hospital, hence, we needed to be able to accurately calculate the physician position within the hospital. This meant to be able to manage live data points that would deliver results back to the patients and then if required, trigger changes.\n        ",
        "https://player.vimeo.com/video/455760404?autoplay=1&muted=1&loop=1"
      ]),
    /* Custom */Block.__(5, [React.createElement(Heading$GlazedWebsite.make, {
              level: /* H3 */2,
              className: inPageHeading,
              children: "We were able to identify scheduling problems and provide staff and patients with on-time feedback."
            })]),
    /* TextAndImage */Block.__(1, [
        "To capture the most accurate position possible we\'ve used iBeacons connected to a native iOS application through Bluetooth.\n\n          Bluetooth low energy (BLE) beacons are a cost-effective way for apps to obtain the device\'s position while indoors where other methods like GPS are not available.\n\n          Through the strength of the signal beacons emit, apps can roughly estimate how far a user is from them and be able to triangulate the user position from several signals.",
        productImage
      ])
  ];
  var content_nextCase = {
    image: nextCaseImage,
    title: "LVMH",
    area: "Fashion - Chatbots",
    link: "/case-studies/lvmh"
  };
  var content = {
    hero: content_hero,
    brief: content_brief,
    content: content_content,
    nextCase: content_nextCase
  };
  return React.createElement(CaseStudyPageTemplate$GlazedWebsite.make, {
              content: content,
              pageTitle: "Glazed • Boston Hospital",
              pageDescription: "A bespoke iOS application to display the position of the physician inside the hospital."
            });
}

var make = Boston;

var $$default = Boston;

export {
  Styles ,
  pageImagesQuery ,
  make ,
  $$default ,
  $$default as default,
  
}
/* inPageHeading Not a pure module */
